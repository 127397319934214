.question-page {
  height: 100vh;
  background-color: #B0ACAD;
  position: relative;
  display: flex;
  flex-direction: column;

  .questions-container {
    z-index: 8;
    display: flex;
    justify-content: space-between;
    padding: 0 48px;

    .questions-set {
      display: flex;
      gap: 20px;
      width: 33%;
      flex-direction: column;

      .question {
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        min-height: 68px;

        span {
          font-size: 36px;
        }

        &:hover {
          background-color: #efefef;
          box-shadow: 0 21px 20px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .video-container {
    z-index: 5;
    width: 50%;
    height: 100vh;
    display: flex;
    position: absolute;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    user-select: none;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    video {
      object-fit: cover;
      width:100%;
      position: absolute;
      height: 100vh;
    }

    img {
      z-index: 10;
    }

    .humans-logo {
      max-width: 477px;
      margin-top: 150px;
      width: 50%;
    }

    .guide-logo {
      max-width: 334px;
      margin-bottom: 60px;
      width: 50%;
    }
  }
}
