@font-face {
    font-family: 'AllianceNo1';
    src:  url('../public/assets/fonts/AllianceNo1-Regular.woff') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AllianceNo1';
    src:  url('../public/assets/fonts/AllianceNo1-Bold.woff') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

*, html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'AllianceNo1';
}

body {
    width: 100%;
    height: 100vh;
}
