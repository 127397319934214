.swiper-container {
    width: 100%;
    z-index: 10;

  .swiper {
    width: 100%;
    padding:  35px 48px 60px;
  }

  .swiper-slide {
    width: fit-content;

    .flag-container {
      width: 135px;
      display: flex;
      padding: 9px;
      min-height: 106px;
      border: 1px solid rgba(255, 255, 255, 0.35);
      border-radius: 22px;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
      user-select: none;

      &.active {
        background-color: white;
        border: 1px solid transparent;
        box-shadow: 0 21px 20px rgba(0, 0, 0, 0.06);
      }

      flex-direction: column;
      align-items: center;

      img {
        border-radius: 15px;
        height: 58px;
      }

      span {
        margin-top: 11px;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
      }
    }
  }
}
